<template>
  <div class="chart" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <canvas :width="size" :height="size" ref="canvasRef"/>
      <div class="core" :style="{ height: size + 'px' }">
        <div class="wrapper">
          <div class="subject">
            <span>총 </span>
            <span class="big">{{ state.totalCount }}</span>
            <span>개 참여</span>
          </div>
          <div class="value">
            <b>{{ $lib.getNumberFormat(Math.floor(state.totalAmount / 10000)) }}</b>
            <span>만원</span>
          </div>
        </div>
      </div>
      <div class="lines">
        <ul class="tight">
          <li class="font-sm" v-for="(v, idx) in state.values" :key="idx" :style="v.style.list">
            <div class="wrapper" :style="v.style.wrapper">
              <span class="dot" :style="{ background: v.style.line.borderTopColor}"></span>
              <span>{{ v.label }}</span>
            </div>
            <div class="line" :style="v.style.line"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, watch} from "@vue/composition-api";
import lib from "@/scripts/lib";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "componentPieChart";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    values: Array,
    count: Boolean
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({
      loaded: false,
      totalCount: 0,
      totalAmount: 0,
      values: [],
      colors: []
    });

    const canvasRef = ref();
    const size = 200;

    const load = (func) => {
      let amount = 0;

      state.totalCount = props.values.map(v => v.count).reduce((sum, x) => sum + x);
      state.totalAmount = props.values.map(v => v.amount).reduce((sum, x) => sum + x);
      state.colors = ["#27caa1", "#03719C", "#343837", "#F2A341", "#F25835"];

      if (props.values.length >= 5) {
        lib.shuffle(props.values);
        lib.shuffle(state.colors);
      }

      for (let v of props.values) {
        state.values.push({
          title: v.title,
          count: v.count,
          amount: v.amount,
          get label() {
            return `${this.title} ${lib.getNumberFormat(this.amount)}원(${(props.count ? v.count + "건 / " : "") + (this.amount / state.totalAmount * 100).toFixed(1)}%)`;
          },
          style: {
            list: {
              top: 0,
              left: 0,
              paddingTop: "5px",
              paddingLeft: "5px",
              paddingRight: "5px",
            },
            wrapper: {
              opacity: 0,
              transform: `translate(${(amount + v.amount / 2) / state.totalAmount <= 0.5 ? "-" : ""}25px, 25px)`,
            },
            line: {
              borderTopColor: "#fff",
              width: 0,
              left: "auto",
              right: "auto",
            }
          }
        });

        amount += v.amount;
      }

      func();
    };

    watch(() => props.values, () => {
      load(() => {
        const canvas = canvasRef.value;
        const ctx = canvas.getContext("2d");
        let idx = 0;
        let startAngle = -(Math.PI * 2 * 0.25);
        const fps = 60;

        const options = {
          speed: 2, // 애니메이션 속도
          thickness: 0.75 // 두께
        };

        const split = fps / options.speed / state.values.length;

        const animate = () => {
          let limitAngle = Math.PI * 2 * (state.values[idx].amount / state.totalAmount);
          let drawIdx = 0;

          const draw = () => {
            const endAngle = startAngle + (limitAngle * ++drawIdx / split);
            const radius = Math.min(ctx.canvas.width / 2, ctx.canvas.height / 2) - 1;

            ctx.fillStyle = state.colors[idx % state.colors.length];
            ctx.beginPath();
            ctx.moveTo(canvas.width / 2, canvas.height / 2);
            ctx.arc(canvas.width / 2, canvas.height / 2, radius, startAngle, endAngle);
            ctx.lineTo(canvas.width / 2, canvas.height / 2);
            ctx.fill();
            ctx.strokeStyle = "#fff";
            ctx.stroke();

            // draw donut
            ctx.fillStyle = "#fff";
            ctx.beginPath();
            ctx.arc(canvas.width / 2, canvas.height / 2, radius * 0.5 / options.thickness, 0, Math.PI * 2);
            ctx.fill();
            ctx.strokeStyle = "#fff";
            ctx.stroke();

            const plate = () => {
              const ratio = state.values[idx].amount / state.totalAmount;
              const middleAngle = startAngle + Math.PI * 1 * ratio;
              const middleX = canvas.width / 2 + radius * Math.cos(middleAngle);
              const middleY = canvas.height / 2 + radius * Math.sin(middleAngle);
              const paddingSide = 50;
              state.values[idx].style.line.borderTopColor = state.colors[idx % state.colors.length];
              state.values[idx].style.line.width = "100%";
              state.values[idx].style.wrapper.opacity = 1;
              state.values[idx].style.wrapper.transform = "translate(0, 0)";
              state.values[idx].style.list.top = middleY + "px";

              if (middleX > canvas.width / 2) {
                state.values[idx].style.list.left = middleX + "px";
                state.values[idx].style.list.paddingLeft = paddingSide + "px";
                state.values[idx].style.line.left = "0";
              } else {
                ctx.font = "14px " + definitions.font;
                const aspectWidth = ctx.measureText(state.values[idx].label).width + 15 + paddingSide;
                state.values[idx].style.list.left = middleX - aspectWidth + "px";
                state.values[idx].style.list.paddingRight = paddingSide + "px";
                state.values[idx].style.line.right = "0";
              }
            };

            if (drawIdx < split) {
              requestAnimationFrame(draw);
            } else if (idx < state.values.length - 1) {
              plate();
              const ratio = state.values[idx++].amount / state.totalAmount;
              startAngle += Math.PI * 2 * ratio;
              animate();
            } else {
              plate();
              state.loaded = true;
            }
          };

          requestAnimationFrame(draw);
        };

        animate();
      });
    });

    return {component, state, canvasRef, size};
  }
});
</script>

<style lang="scss" scoped>
.chart {
  text-align: center;

  > .wrapper {
    display: inline-block;
    position: relative;

    canvas {
      display: inline-block;
    }

    .core {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      > .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        margin-top: $px-1;

        .subject {
          font-size: $px13;
          opacity: 0;
          transform: translateY(25px);
          transition: opacity 0.4s, transform 0.4s;

          span {
            color: #3c3c3c;

            &.big {
              font-size: $px15;
            }
          }
        }

        .value {
          opacity: 0;
          transform: translateY(25px);
          transition: opacity 0.4s, transform 0.4s;

          > b {
            font-size: $px21;
          }
        }
      }
    }

    .lines {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      ul li {
        margin-bottom: $px10;
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        padding: $px5;

        > .wrapper {
          transition: opacity 0.4s, transform 0.4s;

          span {
            vertical-align: middle;

            &.dot {
              width: $px10;
              height: $px10;
              display: inline-block;
              margin-right: $px10;
            }
          }
        }

        > .line {
          border-top: $px1 dotted;
          position: absolute;
          top: 0;
          transition: width 0.4s;
        }
      }
    }
  }

  &:not(.skeleton) {
    > .wrapper .core > .wrapper {
      .subject, .value {
        opacity: 1;
        transform: translateY(0);
      }

      .value {
        transition-delay: 0.1s;
      }
    }
  }

  @media (max-width: 991px) {
    > .wrapper {
      .lines {
        position: static;
        padding-top: $px15;
        text-align: left;

        ul li {
          position: static;
          padding: $px5 !important;
          margin-bottom: $px3;

          > .line {
            display: none;
          }
        }
      }
    }
  }
}
</style>