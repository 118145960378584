<template>
  <div class="my-page-participant my-page">
    <ul class="nav" :class="{skeleton: !state.loaded.list}">
      <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
        <router-link :to="m.path" :class="state.menu.name === m.name ? 'active btn-bordered-point': 'btn-light'" class="btn">{{ m.title }}</router-link>
      </li>
    </ul>
    <div class="content">
      <div class="wrapper" ref="wrapRef">
        <template v-if="state.projects.length">
          <div class="status row" :class="{skeleton: !state.loaded.amount}">
            <div class="left col-lg-6">
              <div class="rect current">
                <div class="wrapper">
                  <div class="subject font-sm">
                    <span v-if="state.projectType === 'mock'">모의투자 가능 잔액</span>
                    <span v-else-if="state.projectType === 'invest'">현재 투자 중인 금액</span>
                    <span v-else>현재 후원 중인 금액</span>
                  </div>
                  <span class="value">{{ state.amounts.current }}원</span>
                  <span class="ico" style="background: '#825ee4'">
                    <i class="fa fa-krw"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="right col-lg-6">
              <div class="rect total mb-0">
                <div class="wrapper">
                  <div class="subject font-sm">
                    <span v-if="state.projectType === 'mock'">모의투자한 총 금액</span>
                    <span v-else-if="state.projectType === 'invest'">총 투자 금액</span>
                    <span v-else>총 후원 금액</span>
                  </div>
                  <span class="value">{{ state.amounts.total }}원</span>
                  <span class="ico" style="background: '#825ee4'">
                    <i class="fa fa-krw"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="table-outer table-responsive-sm" v-if="state.projectType === 'mock' && state.contests.length">
            <table class="table font-sm">
              <thead>
              <tr>
                <th>모의투자 대회</th>
                <th style="width: 24%" class="period">대회기간</th>
                <th style="width: 14%">투자금액</th>
                <th style="width: 14%">투자가능잔액</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(c, idx) in state.contests" :key="idx">
                <td>
                  <router-link :to="`/partnership/${c.contestSeq}/mock`">{{ c.contestName }}</router-link>
                </td>
                <td class="period">{{ c.fundingStartDate }}~{{ c.fundingEndDate }}</td>
                <td>{{ $lib.getNumberFormat(c.expenseAmt) }}{{ c.currencyUnit }}</td>
                <td>{{ $lib.getNumberFormat(c.investPossibleLimit - c.expenseAmt) }}{{ c.currencyUnit }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="list row" :class="{skeleton: !state.loaded.list }" v-for="(p, idx) in state.projects" :key="idx">
            <template v-if="state.projectType === 'invest'">
              <div class="col-lg-5 thumb">
                <router-link :to="`/invest/${p.investSeq}`" class="img" title="프로젝트로 이동"
                             :style="{ backgroundImage: `url(${$definitions.urls.imgCdn + p.thumbFilePath})`, backgroundPosition: p.mainImagePosition ? p.mainImagePosition : 'center' }"></router-link>
              </div>
              <div class="col-lg-7 texts">
                <div class="wrapper">
                  <div class="badges clearfix">
                    <span class="badge badge-point">{{ p.stockTypeText }}</span>
                    <span class="badge badge-dark" v-if="p.fundingType === '02'">사모</span>
                    <span class="badge badge-dark" v-if="p.rewardFlag === 'Y'">리워드</span>
                    <span class="badge badge-dark" v-if="p.incomeDeductionFlag === 'Y'">소득공제</span>
                    <template v-if=" p.regState === 'STATUS_0006'">
                      <template v-if="p.cancelFlag === 'Y'">
                        <span class="badge badge-dark">투자 취소</span>
                      </template>
                      <template v-else-if="p.cancelFlag === 'N' && p.endFlag === 'Y'">
                        <span class="badge badge-dark" v-if="p.successFailFlag === 'S'">펀딩 성공</span>
                        <span class="badge badge-dark" v-if="p.successFailFlag === 'F'">펀딩 실패</span>
                      </template>
                      <template v-else-if="p.cancelFlag === 'N' && p.endFlag === 'N'">
                        <span class="badge badge-dark">진행중</span>
                      </template>
                    </template>
                    <template v-else-if=" p.regState === 'STATUS_0007'">
                      <span class="badge badge-dark">보류</span>
                    </template>
                  </div>
                  <div class="title clearfix">
                    <router-link class="font-base" :to="`/invest/${p.investSeq}`" :title="p.projectName">{{ p.projectName }}</router-link>
                  </div>
                  <div class="amount">
                    <span>
                      <b class="font-base">{{ $lib.getNumberFormat(p.myAmt) }}원 </b>
                      <span>투자 </span>
                    </span>
                  </div>
                  <div class="target">
                    <span>모인 금액: {{ $lib.getNumberFormat(p.subscribeAmt) }}원</span>
                  </div>
                  <div class="date">
                    <span>펀딩 기간: </span>
                    <span>{{ p.fundingStartDate }}</span>
                    <span v-if="p.fundingEndDate"> ~ {{ p.fundingEndDate }}</span>
                  </div>
                  <div class="date">
                    <span>투자일: {{ p.accountDate }}</span>
                  </div>
                  <div class="act">
                    <button class="btn btn-light font-xs" @click="open(p.subscribeSeq)">자세히 보기</button>
                    <button class="btn btn-light font-xs" @click="sign(p)" v-if="p.investSeq === 240 || (p.fundingType === '02' && p.stockTypeText === '채권' && p.successFailFlag === 'S' && p.endFlag === 'Y')">온라인 서명</button>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-lg-5 thumb">
                <router-link :to="`/reward/${p.projectSeq}`" class="img" title="프로젝트로 이동"
                             :style="{ backgroundImage: `url(${$definitions.urls.imgCdn + p.thumbFilePath})`, backgroundPosition: p.mainImagePosition ? p.mainImagePosition : 'center' }"></router-link>
              </div>
              <div class="col-lg-7 texts">
                <div class="wrapper">
                  <div class="badges clearfix">
                    <template v-if="p.fundingType === 'A'">
                      <span class="badge badge-point">성공해야 리워드</span>
                      <span class="badge badge-dark">예약 결제({{ p.paymethod }})</span>
                    </template>
                    <template v-else-if="p.fundingType === 'K'">
                      <span class="badge badge-point">무조건 리워드</span>
                      <span class="badge badge-dark">
                      <template v-if="p.incomingChk === 0">
                        <span>{{ p.paymethod }}</span>
                        <span v-if="!['포인트', '포인트 + 쿠폰'].includes(p.paymethod) && p.omcpay > 0">/포인트</span>
                      </template>
                      <template v-else-if="p.incomingChk === 1 && p.paymethod === '가상계좌'">
                        <span>가상계좌(참여예약중)</span>
                        <span v-if="!['포인트', '포인트 + 쿠폰'].includes(p.paymethod) && p.omcpay > 0">/포인트</span>
                      </template>
                      </span>
                    </template>
                    <template v-if="p.regState === 'STATUS_0006'">
                      <span class="badge" :class="p.regStateName === '진행중' ? 'badge-primary' : 'badge-dark'" v-if="p.progressOrder === '1'">{{ p.regStateName }}</span>
                      <span class="badge badge-danger" v-else-if="p.progressOrder === '2'">
                        <template v-if="p.successFailFlag === 'S'">펀딩 성공</template>
                        <template v-else-if="p.successFailFlag === 'F'">{{ p.fundingType === "A" ? "펀딩 실패" : "펀딩 마감" }}</template>
                      </span>
                    </template>
                    <span class="badge" :class="p.regStateName === '진행중' ? 'badge-primary' : 'badge-dark'" v-if="p.regState === 'STATUS_0007'">{{ p.regStateName }}</span>
                  </div>
                  <div class="title clearfix">
                    <router-link class="font-md" :to="`/reward/${p.projectSeq}`" :title="p.projectName">{{ p.projectName }}</router-link>
                  </div>
                  <div class="amount">
                    <span>
                      <template v-if="state.projectType === 'reward'">
                        <b class="font-base">{{ $lib.getNumberFormat(p.myAmt) }}원 </b>
                        <span>후원 </span>
                      </template>
                      <template v-else>
                        <b class="font-base">{{ $lib.getNumberFormat(p.myAmt) }}{{ p.currencyUnit }} </b>
                        <span>모의투자 </span>
                      </template>
                    </span>
                  </div>
                  <div class="date">
                    <span v-if="state.projectType !== 'mock'">후원일: </span>
                    <span v-else>투자일: </span>
                    <span>{{ p.regDate }}</span>
                  </div>
                  <div class="contest" v-if="state.projectType === 'mock'">
                    <span>대회명: {{ p.contestName }}</span>
                  </div>
                  <div class="reward" v-else>
                    <span v-if="p.rewardCount">리워드: {{ p.rewardTitle }}</span>
                    <span v-else>리워드 없이 참여</span>
                  </div>
                  <div class="target">
                    <span>모인 금액: {{ $lib.getNumberFormat(p.expenseAmt) }}원</span>
                  </div>
                  <div class="date">
                    <span>
                      <span>펀딩 기간: </span>
                      <span>{{ p.investStartDate }}</span>
                      <span v-if="p.investEndDate"> ~ {{ p.investEndDate }}</span>
                    </span>
                  </div>
                  <div class="act">
                    <template v-if="state.projectType === 'mock'">
                      <button class="btn btn-light font-xs" @click="cancel(p)" v-if="p.progressOrder === '1'">투자 취소</button>
                    </template>
                    <template v-else>
                      <button class="btn btn-light font-xs" @click="open(p.investorSeq)">자세히 보기</button>
                      <button class="btn btn-point font-xs" @click="openReview(p.investorSeq, p.projectSeq)"
                              v-if="(p.deliveryYn === 'Y' || new Date(p.investEndDate) <= new Date()) && p.wroteReview === 'N' && p.incomingChk === 0 && p.rewardCount && (p.fundingType === 'K' || (p.fundingType === 'A' && p.successFailFlag === 'S'))">
                        후기 작성하기
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="page">
            <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
          </div>
        </template>
        <div class="pt-10 pb-10" v-else-if="state.loaded.list">
          <NoData/>
        </div>
      </div>
    </div>
    <ul class="guide bottom tight font-sm" v-if="state.projectType === 'reward'">
      <li>후원하기 취소는 펀딩 종료일 1일 전까지 가능하며, 펀딩 종료일 당일 및 펀딩 마감 이후에는 취소가 불가능합니다. 또한 펀딩 기간 중 리워드가 배송된 경우에도 취소가 불가능하오니 이 부분 양해 바랍니다.</li>
      <li>배송정보 수정은 펀딩 종료일까지 수정이 가능합니다. 수정은 프로젝트 [자세히보기] > [배송지 정보] > [수정] 버튼을 통해 가능합니다. 다만, 펀딩기간 중 리워드가 배송되는 프로젝트라면 참여한 프로젝트 진행자에게 문의해주세요.</li>
      <li>가상계좌는 유효 기간 내에 입금을 진행해주세요.</li>
      <li>금액대별 리워드 변경을 원할 시 기존 참여를 취소해주시고, 다시 참여 해주세요. 리워드 옵션 변경 시에는 관리자 문의를 이용해주세요.</li>
    </ul>
    <ul class="guide bottom tight font-sm" v-else-if="state.projectType === 'invest'">
      <li>투자취소는 투자마감시각까지 가능합니다.</li>
      <li>투자금액 변경은 투자취소 후 재투자를 통해 가능하며, 이에 따라 선착순 배정 순서가 변경될 수 있습니다.</li>
    </ul>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Card from "@/components/Card.vue";
import router from "@/scripts/router";
import NoData from "@/components/NoData";
import PieChart from "@/components/PieChart";
import store from "@/scripts/store";
import Pagination from "@/components/Pagination";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageMyPageParticipant";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Pagination, PieChart, NoData, Card},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "펀딩 프로젝트");

      const pathArr = router.app.$route.path?.split("/");
      state.menu = state.menus[0];

      if (pathArr?.length > 3) {
        state.projectType = pathArr[3];
      }

      for (let i in state.menus) {
        if (router.app.$route.path.startsWith(state.menus[i].path)) {
          state.menu = state.menus[i];
          break;
        }
      }

      state.projectType && load();

      // temp code for testing
      // setTimeout(() => {
      //   state.projectTypes = [
      //     {title: "후원형", amount: 8000000, count: 190},
      //     {title: "증권형", amount: 2000000, count: 24},
      //   ];
      //
      //   state.projectCategories = [
      //     {title: "테크", amount: 2140000, count: 30},
      //     {title: "캠페인", amount: 870000, count: 14},
      //     {title: "리빙", amount: 2000000, count: 52},
      //     {title: "환경", amount: 3000000, count: 76},
      //     {title: "푸드", amount: 1990000, count: 42}
      //   ];
      // });
    });

    const state = reactive({
      projectType: "",
      loaded: {
        list: false,
        amount: false
      },
      contests: [],
      amounts: {
        current: "0000000",
        total: "0000000",
      },
      menu: {
        name: "",
        title: "",
        path: ""
      },
      menus: [
        // {name: "all", title: "전체", path: "/mypage/participant"},
        {name: "reward", title: "후원형", path: "/mypage/participant/reward"},
        {name: "invest", title: "증권형", path: "/mypage/participant/invest"},
        {name: "mock", title: "모의투자", path: "/mypage/participant/mock"},
      ],
      projectTypes: [],
      projectCategories: [],
      projects: [],
      pagination: null,
      acCfmResultCdList: [
        {name: "", detail: "",}
      ],
    });

    const wrapRef = ref();

    const open = (detailSeq) => {
      store.commit("openModal", {
        name: "Participant",
        params: {
          detailSeq,
          projectType: state.projectType
        },
        callback: `${component.name}.load`
      });
    };

    const openReview = (investorSeq, projectSeq) => {
      store.commit("confirm", {
        message: "리워드를 받으셨나요? 꼭 받으신 후에 작성해주세요!",
        allowTxt: "받았어요",
        disallowTxt: "아직 받지 못했어요",
        allow() {
          store.commit("openModal", {
            name: "Review",
            params: {
              investorSeq,
              projectSeq
            }
          });
        },
        disallow() {
          store.commit("setSwingMessage", "리워드를 받으신 후에 후기를 작성해주세요.");
        }
      });
    };

    const openReviewEdit = (investorSeq, projectSeq, id) => {
      store.commit("openModal", {
        name: "Review",
        params: {
          investorSeq,
          projectSeq,
          edit: true,
          id,
        }
      });
    };

    const cancel = async (p) => {
      store.commit("confirm", {
        message: `프로젝트(${p.projectName})의 투자를 취소하시겠습니까?`,
        allow() {
          http.put(`/api/participant/mock/projects/${p.investorSeq}`).then(() => {
            store.commit("setSwingMessage", "해당 프로젝트의 투자를 취소하였습니다.");
            load();
          }).catch(httpError());
        }
      });
    };

    const sign = (project) => {
      store.commit("openModal", {
        name: "SignaturePad",
        params: {project},
        callback: `${component.name}.load`
      });
    };

    const load = async (num) => {
      const args = {
        pageIndex: num || 1,
        pageSize: 5,
        pageUnit: 5,
      };

      num && window.scrollTo({top: 0, left: 0, behavior: "smooth"});

      for (let i = 0; i < 3; i += 1) {
        state.projects.push({
          projectName: "Wait a moment",
          rewardTitle: "Wait",
          paymethod: "Wait",
          myAmt: 1000000,
          targetAmt: 1000000,
          subscribeAmt: 1000000,
          expenseAmt: 1000000,
          investStartDate: "2021-12-20",
          investEndDate: "2021-12-21",
          fundingStartDate: "2021-12-20",
          fundingEndDate: "2021-12-21",
          regDate: "2021-12-20",
          stockTypeText: "wait",
        });
      }

      state.loaded.list = false;
      state.loaded.amount = false;

      if (state.projectType === "reward") {
        args.mockFlag = "N";
      } else if (state.projectType === "mock") {
        args.mockFlag = "Y";
      }

      const res1 = await http.get(`/api/participant/${state.projectType}/projects`, args).catch(httpError());
      state.loaded.list = true;

      if (res1.error) {
        return;
      }

      if (state.projectType === "reward" && Array.isArray(res1?.data?.body?.rewardProjectList)) {
        state.projects = res1.data.body.rewardProjectList;
      } else if (state.projectType === "invest" && Array.isArray(res1?.data?.body?.investProjectList)) {
        state.projects = res1.data.body.investProjectList;
        state.acCfmResultCdList = res1.data.body.acCfmResultCdList;
      } else if (state.projectType === "mock" && Array.isArray(res1?.data?.body?.mockProjectList)) {
        state.projects = res1.data.body.mockProjectList;
      }

      for (let i in state.projects) {
        state.projects[i].rewardTitle = state.projects[i].rewardTitle?.replace("<br>", " ");
      }

      state.pagination = res1?.data?.body?.paginationInfo;

      const pathArr = router.app.$route.path?.split("/");
      const detailSeq = pathArr[pathArr.length - 1];

      const goWithoutDetailSeq = (func) => {
        pathArr.splice(pathArr.length - 1, 1);
        router.replace({path: pathArr.join("/")});
        typeof func === "function" && func();
      };

      if (!state.projects.length) {
        lib.isNumeric(detailSeq) && goWithoutDetailSeq();
        return;
      }

      state.projects.forEach(p => p.regStateName = p.regStateName === "진행" ? "진행중" : p.regStateName);

      const res2 = await http.get(`/api/participant/${state.projectType}/projects`, args).catch(httpError());
      state.loaded.amount = true;

      if (res2.error) {
        return;
      }

      if (state.projectType === "mock") {
        state.amounts.current = lib.getNumberFormat(window.Number(res2.data.body.investableAmt));
        state.amounts.total = lib.getNumberFormat(res2.data.body.expenseAmount);

        http.get("/api/participant/mock/projects", {
          pageIndex: 1, pageSize: 5, pageUnit: 5,
        }).then(({data}) => {
          state.contests = data.body.contestList;
        });
      } else {
        state.amounts.current = lib.getNumberFormat(res2.data.body.currentAmount);
        state.amounts.total = lib.getNumberFormat(res2.data.body.totalAmount);

        lib.isNumeric(detailSeq) && goWithoutDetailSeq(() => {
          open(detailSeq);
        });
      }
    };

    return {component, state, wrapRef, load, open, openReview, openReviewEdit, cancel, sign};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-participant {
  > .content {
    > .wrapper {
      > .row {
        &.status {
          padding: $px5 0 0 0;

          .rect {
            display: block;
            background: #fff;
            padding: $px30 $px25;
            box-shadow: 0 0 $px32 0 rgba(136, 152, 170, 0.15);
            margin-bottom: $px15;
            text-decoration: none;
            transition: border-color 0.18s;
            border-radius: $px4;

            > .wrapper {
              position: relative;

              .subject {
                color: #8898aa;
                font-size: $px13;
                margin-bottom: $px2;
              }

              .value {
                font-weight: 500;
                font-size: $px17;
              }

              .ico {
                position: absolute;
                top: 0;
                right: 0;
                width: $px50;
                height: $px50;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                padding-top: $px13;
              }
            }

            &.current > .wrapper .ico {
              background: #1171ef;
            }

            &.total > .wrapper .ico {
              background: #825ee4;
            }
          }
        }

        &.list {
          font-size: 0;

          .thumb {
            .img {
              background-color: $colorBackground;
              width: 100%;
              padding-top: $ratio43Percent;
              border-radius: $px4;
              border: $px1 solid #eee;
              background-size: auto 100%;
            }
          }

          .texts {
            font-size: $px14;
            padding-top: $px5;

            > .wrapper {

              .badges {
                margin-bottom: $px5;

                .badge {
                  float: left;
                  margin-right: $px2;
                }
              }

              .account {
                color: $colorPrimary;
              }

              .title {
                a {
                  font-weight: 500;
                  display: inline-block;
                  max-width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  float: left;
                }
              }

              .account {
                margin: $px5 0;
              }

              .amount {
                margin: $px5 0 $px11 0;
              }

              .reward {
                margin-top: $px5;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .date {
                margin-top: $px5;
              }

              .target {
                margin-top: $px5;
              }

              .contest {
                margin-top: $px5;
              }

              .act {
                padding-top: $px13;

                .btn {
                  padding: $px12 $px16;
                  margin-right: $px9;
                }
              }
            }
          }

          .mine {
            dl {
              margin: $px15 $px40 0 0;
              float: left;

              dt {
                float: left;
                padding-right: $px15;
              }

              dd {
                float: left;
                position: relative;
                padding-left: $px15;
                margin: 0;

                &:before {
                  background-color: #d6d6d6;
                  content: " ";
                  height: $px9;
                  left: 0;
                  position: absolute;
                  top: $px6;
                  width: $px1;
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          &:not(:first-child) {
            margin-top: $px20;
          }
        }
      }

      .table-outer {
        margin: $px15 0;

        table {
          border: $px1 solid #eee;

          th, td {
            border: 0;
          }

          th {
            background: $colorBackground;
          }
        }
      }

      .page {
        margin-top: $px25;
      }
    }
  }

  > .guide {
    margin-top: $px25;
  }

  .nav.skeleton li a {
    @include skeleton;
  }

  .content {
    > .wrapper > .row {
      &.status.skeleton {
        b, span {
          @include skeleton;
        }
      }

      &.list.skeleton {
        .thumb > .img {
          @include skeleton;
        }

        .texts {
          > .wrapper {
            .badges .badge {
              @include skeleton;
            }

            .title a {
              @include skeleton;
            }

            .amount > span {
              @include skeleton;

              * {
                visibility: hidden;
              }
            }

            .reward > span,
            .target > span,
            .date > span,
            .contest > span,
            .act > .btn {
              @include skeleton;
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .content > .wrapper {
      .row {
        &.status {
          padding-bottom: 0;
          padding-top: 0;
        }

        &.list {
          border-radius: $px4;
          margin: 0;

          > .thumb {
            padding: 0;
          }

          .texts {
            padding: $px15 0;
          }
        }
      }

      .table-outer table {
        margin-bottom: 0;

        th, td {
          white-space: nowrap;
          text-align: center;
          vertical-align: middle;

          &.period {
            display: none;
          }
        }

        td {
          &:not(&.period) {
            white-space: pre-wrap;
          }
        }
      }
    }
  }

}
</style>